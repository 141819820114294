//Action types

import { isScrollingDisabled } from '../../ducks/ui.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  getClassroomData, getClassroomsPercentage
} from '../../util/api';
import { storableError } from '../../util/errors';


export const FETCH_CLASSROOMS_REQUEST = 'app/ClassroomPage/FETCH_CLASSROOMS_REQUEST';
export const FETCH_CLASSROOMS_SUCCESS = 'app/ClassroomPage/FETCH_CLASSROOMS_SUCCESS';
export const FETCH_CLASSROOMS_ERROR = 'app/ClassroomPage/FETCH_CLASSROOMS_ERROR';


export const FETCH_CLASSROOMSPERC_REQUEST = 'app/ClassroomPage/FETCH_CLASSROOMSPERC_REQUEST';
export const FETCH_CLASSROOMSPERC_SUCCESS = 'app/ClassroomPage/FETCH_CLASSROOMSPERC_SUCCESS';
export const FETCH_CLASSROOMSPERC_ERROR = 'app/ClassroomPage/FETCH_CLASSROOMSPERC_ERROR';


//InitialState
const initialState = {
  classrooms: [],
  fetchClassroomsInProgress: false,
  fetchClassroomsError: null,
  classroomsPercentage: [],
  fetchClassroomPercentageInProgress: false,
  fetchClassroomPercentageError: null,

};

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CLASSROOMS_REQUEST:
      return { ...state, fetchClassroomsInProgress: true, fetchClassroomsError: null };
    case FETCH_CLASSROOMS_SUCCESS:
      return {
        ...state,
        classrooms: payload.data,
        fetchClassroomsInProgress: false,
        fetchClassroomsError: null,
      };

    case FETCH_CLASSROOMSPERC_ERROR:
      return { ...state, fetchClassroomPercentageInProgress: false,  fetchClassroomPercentageError: payload };
      case FETCH_CLASSROOMSPERC_REQUEST:
        return { ...state, fetchClassroomPercentageInProgress: true,  fetchClassroomPercentageError: null };
      case FETCH_CLASSROOMSPERC_SUCCESS:
        return {
          ...state,
          classroomsPercentage: payload.data,
          fetchClassroomPercentageInProgress: false,
          fetchClassroomPercentageError: null,
        };
      case FETCH_CLASSROOMS_ERROR:
        return { ...state, fetchClassroomPercentageInProgress: false,  fetchClassroomPercentageError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //


export const fetchClassroomsRequest = () => ({
  type: FETCH_CLASSROOMS_REQUEST,
});
export const fetchClassroomsSuccess = data => ({
  type: FETCH_CLASSROOMS_SUCCESS,
  payload: data,
});

export const fetchClassroomsError = e => ({
  type: FETCH_CLASSROOMS_ERROR,
  error: true,
  payload: e,
});

export const fetchClassroomsPercentageRequest = () => ({
  type: FETCH_CLASSROOMSPERC_REQUEST,
});
export const fetchClassroomsPercentageSuccess = data => ({
  type: FETCH_CLASSROOMSPERC_SUCCESS,
  payload: data,
});

export const fetchClassroomsPercentageError = e => ({
  type: FETCH_CLASSROOMSPERC_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

// Get classroom

export const getClasrooms = (params) => async (dispatch, sdk, getState) => {
  dispatch(fetchClassroomsRequest());
  try {
    const classrooms = await getClassroomData();
    dispatch(fetchClassroomsSuccess(classrooms));
    return;
  } catch (error) {
    dispatch(fetchClassroomsError(storableError(error)));
  }
};

export const getClasroomsPercentage = (params) => async (dispatch, sdk, getState) => {
  dispatch(fetchClassroomsPercentageRequest());
  try {
    const percentage = await getClassroomsPercentage();
    dispatch(fetchClassroomsPercentageSuccess(percentage));
    return;
  } catch (error) {
    dispatch(fetchClassroomsPercentageError(storableError(error)));
  }
};

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {

  try {
    return Promise.all([
      // dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] })),
      dispatch(getClasrooms()),
      dispatch(getClasroomsPercentage()),
    ]);
  } catch (error) {
    // dispatch(showListingError(error));
    throw error;
  }
};
