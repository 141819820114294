export const getUserType = (user) => (user?.attributes?.profile?.publicData?.userType);
import moment from "moment"

export const getUserRole = (user) => {
  return user && user?.id && user?.attributes?.profile?.publicData?.userType
}
export const getListingType = (listing) => {
  return listing && listing?.id && listing?.attributes?.publicData?.listingType
}

export const getMerchantListingId = (user) => {
  return user.id && user?.attributes?.profile?.publicData?.userListingID
}

export const freePlan = user =>
  !!user?.id && !!user?.attributes?.profile?.protectedData?.freePlanData?.isFreePlanUsed;

export const getStripeCustomerId = user =>
  !!user?.id && user?.stripeCustomer?.attributes?.stripeCustomerId;

export const isEmailVerified = user =>
  !!user?.id &&
  (!!user?.attributes?.emailVerified || user?.attributes?.profile?.publicData?.emailVerified);

export const getUserDetails = user => {
  const fullName = user?.attributes?.profile?.firstName
    ? user?.attributes?.profile?.firstName + ' ' + user?.attributes?.profile?.lastName
    : user?.attributes?.profile?.publicData?.fullName;
  let profileImage = null;
  if (!!user?.id && user?.profileImage?.attributes?.variants) {
    if (user?.profileImage?.attributes?.variants?.default) {
      profileImage = user?.profileImage?.attributes?.variants?.default?.url;
    } else {
      profileImage = user?.profileImage?.attributes?.variants['square-small2x']?.url;
    }
  } else {
    profileImage = user?.attributes?.profile?.publicData?.picture;
  }
  const email =
    !!user?.id && user?.attributes?.email
      ? user?.attributes?.email
      : user?.attributes?.profile?.publicData?.email;
  const id = user?.id && user?.id?.uuid;
  const firmId = user?.attributes.profile.publicData?.firmId
    || user?.attributes.profile.publicData?.linkedToFirms?.[0]?.firmId;

  const firmName = user?.attributes?.profile?.publicData?.firmListing?.firmTitle
    ?? user?.attributes?.profile?.publicData?.linkedToFirms?.[0]?.firmName;
  return {
    fullName: fullName ?? DELETED_USER,
    profileImage,
    email,
    id,
    firmName,
    firmId
  };
};

// export const userSubscriptions = user =>

// ( !!user?.id && (user?.attributes?.profile?.protectedData?.subscriptionPlan ?? null));

export const userSubscriptions = user => {
  const monthly = user?.attributes?.profile?.protectedData?.subscriptionPlanMonthly;
  const halfYearly = user?.attributes?.profile?.protectedData?.subscriptionPanHalfYearly;
  const yearly = user?.attributes?.profile?.protectedData?.subscriptionPanYearly;
  (!!user?.id && ((monthly || halfYearly || yearly) ?? null));
}

export const formatAmount = (amount, currencyToggle) => {
  const currencySymbol = currencyToggle === USD ? '$' : 'null';
  const formattedAmount = (amount / 100).toLocaleString('en-US', {
    maximumFractionDigits: 0
  });
  return currencySymbol + formattedAmount;

};

export const firstLetterCapital = text => text?.replace(/(^\w|\.\s\w)/g, char => char.toUpperCase());

export const getUserShortName = (fullName, email) => {
  if (!fullName || !email) return

  if (!!fullName) return fullName.match(/(\b\S)?/g).join('').toUpperCase()

  else return email.split('@')[0].match(/(\b\S)?/g).join('').toUpperCase()
}

export function getAllModelListingIds(data) {
  const modelListingIds = [];
  data.forEach(transaction => {
    if (transaction.attributes && transaction?.attributes?.protectedData?.modelListingId) {
      const modelListingId = transaction?.attributes?.protectedData?.modelListingId;
      modelListingIds.push(modelListingId);
    }
  });
  return modelListingIds;
}

