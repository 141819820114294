//Action types

import { isScrollingDisabled } from '../../ducks/ui.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getParticularClassroomData
} from '../../util/api';
import { storableError } from '../../util/errors';


export const FETCH_CLASSROOMCONTENT_REQUEST = 'app/ClassroomContentPage/FETCH_CLASSROOMS_REQUEST';
export const FETCH_CLASSROOMCONTENT_SUCCESS = 'app/ClassroomContentPage/FETCH_CLASSROOMS_SUCCESS';
export const FETCH_CLASSROOMCONTENT_ERROR = 'app/ClassroomContentPage/FETCH_CLASSROOMS_ERROR';


//InitialState
const initialState = {
  classroomContent: [],
  fetchClassroomContentInProgress: false,
  fetchClassroomContentError: null,

};

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CLASSROOMCONTENT_REQUEST:
      return { ...state, fetchClassroomContentInProgress: true, fetchClassroomContentError: null };
    case FETCH_CLASSROOMCONTENT_SUCCESS:
      return {
        ...state,
        classroomContent: payload.data,
        fetchClassroomContentInProgress: false,
        fetchClassroomsContentError: null,
      };
    case FETCH_CLASSROOMCONTENT_ERROR:
      return { ...state, fetchClassroomContentInProgress: false, fetchClassroomContentError: payload };


    default:
      return state;
  }
}

// ================ Action creators ================ //


export const fetchClassroomContentRequest = () => ({
  type: FETCH_CLASSROOMCONTENT_REQUEST,
});
export const fetchClassroomContentSuccess = data => ({
  type: FETCH_CLASSROOMCONTENT_SUCCESS,
  payload: data,
});

export const fetchClassroomContentError = e => ({
  type: FETCH_CLASSROOMCONTENT_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

// Get classroom content

export const getClasroomContent = (params) => async (dispatch, sdk, getState) => {
  const { id } = params
  dispatch(fetchClassroomContentRequest());
  try {
    const particularClassroomContent = await getParticularClassroomData(id);
    dispatch(fetchClassroomContentSuccess(particularClassroomContent));
    return particularClassroomContent;
  } catch (error) {
    dispatch(fetchClassroomContentError(storableError(error)));
  }
};

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {
  try {
    return Promise.all([
      // dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] })),
      dispatch(getClasroomContent(params)),
    ]);
  } catch (error) {
    // dispatch(showListingError(error));
    throw error;
  }
};
